<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
                <b-button :to="{name:'contracts.list', query:{userId:this.$route.params.id}}">
                    <b-icon icon="list"/>
                    Контракты
                </b-button>
                <b-button to="edit">
                    <b-icon icon="pencil"/>
                    Редактировать
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            >
            </b-table>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "phone",
                    label: "Телефон",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "fio",
                    label: "Фио",
                    sortable: true,
                    formatter(value, key, item) {
                        return `${item.lastname} ${item.firstname} ${item.patronymic ?? ""}`;
                    },
                },
                {
                    key: "passport",
                    label: "Паспорт",
                    sortable: true,
                    formatter(value, key, item) {
                        return `${item.passport_serie} ${item.passport_number}, код подразделения ${item.passport_issuecode}`;
                    },
                },
                {
                    key: "birthday",
                    label: "Дата рождения",
                },
                {
                    key: "address",
                    label: "Адрес",
                },
                {
                    key: "timeZone",
                    label: "TimeZone",
                },
                {
                    key: "meta",
                    label: "Meta",
                },
                {
                    key: "created_at",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "updated_at",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
            ],
            item: null,
        };
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        },
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.axios.get(`/admin_api/users/${id}`).then(async ({data}) => {
                this.item = data;
            });
        },
    },
};
</script>
